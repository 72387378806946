import React, {PureComponent, ReactNode} from "react";
import {Carglass} from "./PartnerIcons/Carglass";
import {Euromaster} from "./PartnerIcons/Euromaster";
import {WidmannWinterholler} from "./PartnerIcons/WidmannWinterholler";
import {Driver} from "./PartnerIcons/Driver";
import {Emigholz} from "./PartnerIcons/Emigholz";
import {Minrath} from "./PartnerIcons/Minrath";
import {Firststop} from "./PartnerIcons/Firststop";
import {Koch} from "./PartnerIcons/Koch";
import {Mezger} from "./PartnerIcons/Metzger";
import {Nobleglass} from "./PartnerIcons/Nobleglass";
import {Reiff} from "./PartnerIcons/Reiff";
import {Haeusler} from "./PartnerIcons/Haeusler";
import {TUEVRheinland} from "./PartnerIcons/TUEVRheinland";
import {Vergoelst} from "./PartnerIcons/Vergoelst";
import {FourFleet} from "./PartnerIcons/FourFleet";
import {Frisch} from "./PartnerIcons/Frisch";
import {Junited} from "@App/components/Home/ContentElements/PartnerIcons/Junited";
import {KsAutoglas} from "@App/components/Home/ContentElements/PartnerIcons/KsAutoglas";

export type GarageIconCollectionProps = {
    headline: string,
};

export class GarageIconCollection extends PureComponent<GarageIconCollectionProps> {

    render(): ReactNode {
        const {
            headline,
        } = this.props

        return (
            <div className={`garageIconCollection px-6 md:px-12 pb-16 md:pb-24`}>
                <h2 className={`text-2xl leading-8 md:text-3xl md:leading-9 font-semibold font-serif text-gray-900 pb-6`}>{headline}</h2>
                <div className={'grid grid-cols-12 gap-x-0.5 gap-y-0.5 items-center bg-white'}>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Euromaster/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <WidmannWinterholler/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <TUEVRheinland/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Carglass/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Driver/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Emigholz/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Minrath/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Firststop/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Koch/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Mezger/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Nobleglass/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Reiff/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Haeusler/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Vergoelst/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <FourFleet/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Frisch/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <Junited/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer flex items-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <KsAutoglas/>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer text-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <span className={'text-lg leading-8 font-semibold text-gray-400'}>und unzählige</span>
                    </div>
                    <div
                        className={'garageIconCollection__IconContainer text-center col-span-6  lg:col-span-2 w-full h-full bg-gray-50 my-0 mx-auto py-8 px-2'}>
                        <span className={'text-lg leading-8 font-semibold text-gray-400'}>freie Werkstätten</span>
                    </div>
                </div>
            </div>
        );
    }
}